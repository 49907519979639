

const hamburger = document.querySelector('.mob-icon');
const nav = document.querySelector('.main-nav');
const body = document.querySelector('body');
const overlay = document.querySelector('.overlay');
const modalPop = document.querySelectorAll('.modal-pop');
const closePop = document.querySelectorAll('.close');
const links = document.querySelectorAll('.scroll-to');
const cardTitle = document.querySelector('.card__title');
const cardBody = document.querySelector('.card__body');
const left = document.querySelector('.product-left');
const right = document.querySelector('.product-right');

window.onscroll = function (e) {
  let scrollingElement = document.scrollingElement || document.documentElement;
  // console.log(scrollingElement.scrollTop);
  left.style.transform = `translateY(-${(scrollingElement.scrollTop * 0.35)}px)`;
  right.style.transform = `translateY(${(scrollingElement.scrollTop * 0.35)}px)`;
}

const productData = {
  Manuka: {
    image: 'manuka-honey-pack--no-shadow-@2x.jpg',
    title: 'Roar Protein Manuka Honey Ham, Roasted Almonds & Cheese 70g',
    description: 'A delicious single serve protein-packed snack consisting of Manuka Honey Ham, Cheddar Cheese, and Roasted Almonds.',
    ingredients: 'Made in Australia from at least 63% Australian ingredients. Manuka Honey Ham [Pork, Water, Manuka Honey, Acidity Regulators (326, 262), Cure [Salt, Mineral Salts (451, 450), Dextrose (Maize or Tapioca), Sugar, Flavours, Antioxidant (316), Preservative (250)]], Tasty Cheese [<strong>Milk</strong>, Salt, Cultures, Enzyme (Non-Animal Rennet), Preservative (200)], Roasted <strong>Almonds</strong>.',
    allergen: 'Contains Milk and Tree Nuts.<br>May contain traces of Gluten, Soy, Peanut, Egg, Lupin and Sesame.',
    information: 'Serving size: 70g<br>Servings per pack: 1',
    data: [
      ['Energy', '1050 kJ', '12%', '24.2 g'],
      ['Protein', '16.9 g', '34%', '26.5 g'],
      ['Fat, total', '18.6 g', '27%', '1500 kJ'],
      ['- saturated', '7.6 g', '32%', '10.9 g'],
      ['Carbohydrate', '3.1 g', '1%', '4.5 g'],
      ['– sugars', '2.1 g', '2%', '3.1 g'],
      ['Sodium', '504 mg', '22%', '720 mg'],
      ['Ham', '29.0 g', '–', '41.4 g'],
      ['Cheese', '26.0 g', '–', '37.1 g'],
      ['Almonds', '15.0 g', '–', '21.4 g'],
    ]
  },
  Danish: {
    image: 'danish-salami-pack--no-shadow-@2x.jpg',
    title: 'Roar Protein Mild Danish Salami, Roasted Cashews & Cheese 70g',
    description: 'A delicious single serve protein-packed snack consisting of Mild Danish Salami, Cheddar Cheese, and Roasted Cashews.',
    ingredients: 'Mild Danish Salami [Meat Including Pork, Salt, Spices (Including Chilli), Lactose (Milk), Maltodextrin (Maize or Tapioca), Dextrose (Maize or Tapioca), Mineral Salts (451, 450), Antioxidant (316), Dried Vegetables (Including Garlic, Onion), Sugar, Starter Culture, Preservative (250)], Tasty Cheese [<strong>Milk</strong>, Salt, Cultures, Enzyme (Non-Animal Rennet), Preservative (200)], Roasted <strong>Cashews</strong> [<strong>Cashews</strong>, Vegetable Oil].',
    allergen: 'Contains Milk and Tree Nuts.<br>May contain traces of Gluten, Soy, Peanut, Egg, Lupin and Sesame.',
    information: 'Serving size: 70g<br>Servings per pack: 1',
    data: [
      ['Energy','1410 kJ','16%','2010 kJ'],
      ['Protein','16.8 g','34%','24.0 g'],
      ['Fat, total','27.9 g','40%','39.8 g'],
      ['- saturated','11.9 g','50%','17 g'],
      ['Carbohydrate','5.1 g','2%','7.2 g'],
      ['- sugars','1.4 g','2%','2.0 g'],
      ['Sodium','712 mg','31%','1020 mg'],
      ['Salami','29.0 g','-','41.4 g'],
      ['Cheese','26.0 g','-','37.1 g'],
      ['Almonds','15.0 g','-','21.4 g'],
      ]
  },
  Chilli: {
    image: 'chilli-salami-pack--no-shadow-@2x.jpg',
    title: 'Roar Protein Chilli Salami, Peanuts & Tasty Cheese 70g',
    description: 'A delicious single serve protein-packed snack consisting of spicy Chilli Salami, Cheddar Cheese, and Roasted Peanuts.',
    ingredients: 'Chilli Salami [Meat Including Pork, Salt, Spices (Including Chilli), Dextrose (Maize or Tapioca), Spice Extract (160c), Antioxidant (316), Mineral Salt (451), Starter Culture, Preservative (250)], Tasty Cheese [<strong>Milk</strong>, Salt, Cultures, Enzyme (Non- Animal Rennet), Preservative (200)], Roasted <strong>Peanuts</strong> [<strong>Peanuts</strong>, Vegetable Oil].',
    allergen: 'Contains Milk and Tree Nuts.<br>May contain traces of Gluten, Soy, Peanut, Egg, Lupin and Sesame.',
    information: 'Serving size: 70g<br>Servings per pack: 1',
    data: [
      ['Energy','1460 kJ','17%','2080 kJ'],
      ['Protein','18.2 g','42%','25.9 g'],
      ['Fat, total','29.2 g','42%','41.7 g'],
      ['- saturated','12.2 g','51%','17.5 g'],
      ['Carbohydrate','3.1 g','1%','4.4 g'],
      ['- sugars','1.3 g','1%','1.9 g'],
      ['Sodium','646 mg','28%','923 mg'],
      ['Salami','29.0 g','-','41.4 g'],
      ['Cheese','26.0 g','-','37.1 g'],
      ['Almonds','15.0 g','-','21.4 g'],
      ]
  },
  Chicken: {
    image: 'lean-chicken-pack--no-shadow-@2x.jpg',
    title: 'Roar Protein Lean Chicken Breast, Roasted Almonds & Cheese 75g',
    description: 'A delicious single serve protein-packed snack consisting of Chicken, Cheddar Cheese, and Roasted Almonds.',
    ingredients: 'Chicken [Chicken Breast, Water, Modified Starch (1414 Maize or Tapioca), Acidity Regulators (326, 262), Salt, Sugar, Soy Protein, Mineral Salt (451), Vegetable Gum (407), Natural Flavours, Preservative (223)], Tasty Cheese [<strong>Milk</strong>, Salt, Cultures, Enzyme (Non-Animal Rennet), Preservative (200)], Roasted <strong>Almonds</strong>.',
    allergen: 'Contains Milk and Tree Nuts.<br>May contain traces of Gluten, Soy, Peanut, Egg, Lupin and Sesame.',
    information: 'Serving size: 75g<br>Servings per pack: 1',
    data: [
      ['Energy','1170 kJ','13%','1570 kJ'],
      ['Protein','16.9 g','34%','22.5 g'],
      ['Fat, total','20.9 g','30%','27.9 g'],
      ['- saturated','8.9 g','37%','11.9 g'],
      ['Carbohydrate','3.9 g','1%','5.2 g'],
      ['- sugars','1.6 g','2%','2.1 g'],
      ['Sodium','509 mg','22%','678 mg'],
      ['Chicken','30.0 g','-','40.0 g'],
      ['Cheese','30.0 g','-','40.0 g'],
      ['Almonds','15.0 g','-','20.0 g'],
      ]
  }
}



function populateData(product) {
  cardTitle.innerHTML = `
    <img class="product" src="img/${productData[product].image}" alt="${productData[product].description}">
    <div>
        <h2>${productData[product].title}</h2>
        <p class="lead">${productData[product].description}</p>
    </div>
  `;

  cardBody.innerHTML = `
    <h3>Ingredients</h3>
    <p>${productData[product].ingredients}</p>
    <h3>Allergen Statement</h3>
    <p>${productData[product].allergen}</p>
    <h3>Nutrition Information</h3>
    <p>${productData[product].information}</p>

    <table>
        <tr>
            <th>Nutrition</th>
            <th>Avg Qty Per Serving</th>
            <th>% Daily Intake* Per Serving</th>
            <th>Avg Qty Per 100g</th>
        </tr>
        ${ productData[product].data.map(x => `<tr>${ x.map( y => `<td>${y}</td>`).join('') }</tr>`).join('') }
        <tr>
          <td colspan="4">* Based on an average adult diet of 8700 kJ.</td>
        </tr>
    </table>
  `;
}


/* Modal pop-open event listner for any tag with the class name .modal-pop */
for (const modalLink of modalPop) {
  modalLink.addEventListener('click', (e) => {
    e.preventDefault();
    populateData(e.target.getAttribute('data-prod'));
    overlay.classList.add('launch');
    setTimeout( () => {
      body.classList.add('open');
    },0)
  })
}

/* Modal close event listner for any tag with the class name .close */
for (const close of closePop) {
  close.addEventListener('click', (e) => {
    body.classList.remove('open');
    setTimeout( () => {
      overlay.classList.remove('launch');
    },300)
  })
}

/* Scroll to event listner for <a> tags that have the class name .scroll-to */
for (const link of links) {
  link.addEventListener('click', (e) => {
    e.preventDefault();
    hamburger.classList.remove('active');
    nav.classList.remove('active');
    const href = e.target.getAttribute('href');
    const offsetTop = document.querySelector(href).offsetTop - 20;
  
    scroll({
      top: offsetTop,
      behavior: 'smooth'
    });
  })
}

/* Mobile hamburger nav open function */
hamburger.addEventListener('click', () => {
  hamburger.classList.toggle('active');
  nav.classList.toggle('active');
})